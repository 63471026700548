import React from 'react'


class Error extends React.Component {

    render() {
        return (
            <div className="content">
                <img width="128px" src="/static/404.png" />
                <h1 className="title">Page Not Found</h1>
                <h1 className="subtitle">Sorry. We can't find this page.</h1>
                <h1 className="subtitle">Click <a href="/">here</a> to go back</h1>
                <style>{`
                    * {
                        box-sizing: border-box;
                        font-size: 1.0em;
                        font-smooth: always;
                        background-color: #EDF1F5;
                    }
                    h1{
                        font-size: 2.0em;
                    }
                    .content{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        min-height: 100vh;
                    }
                    .title{
                       margin: 1.0em;
                       font-size: 2.2em;
                       color: #3d9ae2;
                    }
                    .subtitle{
                        font-size: 1.2em;
                        color: #AAA;
                    }
                `}</style>
            </div>
        )
    }
}

export default Error
